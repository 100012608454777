export const state = {
}
export const getters = {
    getByProperty: state => (key) => state[key] ? state[key] : null,
}
export const mutations = {
    setProperty(state, keyValueArray) {
        for (const {key, value} of keyValueArray) {
            state[key] = value
        }
    }
}