import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import appConfig from "../../app.config";
import store from '@/state/store';

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  document.documentElement.setAttribute("data-layout-mode", localStorage.getItem("data_layout_mode") ?? 'light')

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  if (!authRequired) return next();
  
  if(JSON.parse(localStorage.getItem('user')) == null){
    const redirectFrom = routeTo.fullPath.includes('/lockscreen') ? '/' : routeTo.fullPath;
    if(redirectFrom.includes('formulario_resposta')){
      return next({ name: 'signin-with-email-basic', query: { redirectFrom } });
    }
    return next({ name: 'signin-basic', query: { redirectFrom } });
  }

  // Check token validity
  if(!(['signin-basic', 'lock-screen-basic']).includes(routeTo.name)){
    const user = JSON.parse(localStorage.getItem('user'));
    const jwt = user.token;
    
    try{
      if(jwt === ''){
        return next({ name: 'lock-screen-basic', query: 
          {
            redirectFrom: routeTo.fullPath,
          } 
        });
      }

      return next()
    }catch(error){
      console.log(error)
      return next({ name: 'signin-basic', query: { redirectFrom: routeTo.fullPath, message: 'Ocorreu um erro inesperado.' } });
    }
  }
  next()
});

router.beforeEach((routeTo, _, next) => {
  if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {

    // Check if auth is required on this route
    // (including nested routes).
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

    // If auth isn't required for the route, just continue.
    if (!authRequired) return next();

    // If auth is required and the user is logged in...
    if (store.getters['auth/loggedIn']) {
      // Validate the local user token...
      return store.dispatch('auth/validate').then((validUser) => {
        // Then continue if the token still represents a valid user,
        // otherwise redirect to login.
        validUser ? next() : redirectToLogin();
      });
    }

    // If auth is required and the user is NOT currently logged in,
    // redirect to login.
    redirectToLogin();

    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line no-inner-declarations
    function redirectToLogin() {
      // Pass the original route to the login component
      next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
    }
  } else {
    // const publicPages = ['/login', '/register', '/forgot-password'];
    const publicPages = ['/login', '/login/email', '/auth/signup-basic', '/register', '/auth/reset-pwd-basic', '/forgot-password'];
    const authpage = !publicPages.includes(routeTo.path);
    const loggeduser = localStorage.getItem('user');

    if (authpage && !loggeduser) {
      return next({name: 'signin-basic'});
    }

    next();
  }
});



router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }

  if(!['dynamic-module-tree-program', 'dynamic-module-tree-program-departamento'].includes(routeTo.name)){
    document.title = routeTo.meta.title + ' | ' + appConfig.title;
  }

  next();
});

router.afterEach((to, from) => {
  localStorage.setItem('lastAcessedRouteName', from.name)
})

export default router;
