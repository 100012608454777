export const state = {
    id_program: null,
    page: null,
    searchString: null,
    ordenacao: null,
    dataMovimento: null,
    beingReturned: false,
    showPreActionModal: false,
    rotaFinanceiroVisualizarOcultos: null,
    tableConfigComponentRightPosition: null,
    tableVisualizarpainel: null
}

export const getters = {
    idProgram: state => state.id_program,
    page: state => state.page,
    searchString: state => state.searchString,
    ordenacao: state => state.ordenacao,
    dataMovimento: state => state.dataMovimento,
    beingReturned: state => state.beingReturned,
    currentPageData: state => state.currentPageData,
    tableConfigComponentRightPosition: state => state.tableConfigComponentRightPosition,
    tableVisualizarpainel: state => state.tableVisualizarpainel
}

export const mutations = {
    setCurrentPageData(state, currentPageData) {
        state.currentPageData = currentPageData
    },
    setIdProgram(state, id_program) {
        state.id_program = id_program
    },
    setPage(state, page) {
        state.page = page
    },
    setSearchString(state, searchString) {
        state.searchString = searchString
    },
    setOrdenacao(state, ordenacao) {
        state.ordenacao = ordenacao
    },
    setDataMovimento(state, dataMovimento) {
        state.dataMovimento = dataMovimento
    },
    setBeingReturned(state, beingReturned) {
        state.beingReturned = beingReturned
    },
    setTituloFinanceiroVisualizarOcultos(state, value) {
      state.rotaFinanceiroVisualizarOcultos = value
    },
    setTableConfigComponentRightPosition(state, value) {
        state.tableConfigComponentRightPosition = value
    },
    setVisualizarpainel(state, value) {
        state.tableVisualizarpainel = value
    }
}