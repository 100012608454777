import axios from "axios";
import { healthAndJwtInterceptors } from "./api.interceptors";

const ApiService = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
});



ApiService.interceptors.request.use(healthAndJwtInterceptors, function (error) {
    return Promise.reject(error);
});

ApiService.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    throw error
})

export { ApiService }