export const state = {
    loadedFilter: null,
    searchRoute: null,
    data: [],
    // loadedFilter: {
    //     id_filtro_programa
    //     conditionBuilders: [],
    //     conditions: []
    // }
}
export const getters = {
    loadedFilter: state => state.loadedFilter,
    searchRoute: state => state.searchRoute,
    data: state => state.data
}
export const mutations = {
    setLoadedFilter(state, filter) {
        state.loadedFilter = filter
    },
    setSearchRoute(state, route) {
        state.searchRoute = route
    },
    setData(state, data) {
        state.data = data
    },
    appendData(state, object) {
        state.data.push(object)
    }
}