import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVue3, { BToastPlugin } from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .use(BToastPlugin)
    .component(VueFeather.type, VueFeather)
    .use(Particles)
    .use(i18n)
    .use(vClickOutside)

app.config.globalProperties.window = window
app.mount('#app');