export const state = {
    filterOptions: {},
    filterArray: []
}
export const getters = {
    getfilterValues: state => state.filterOptions.flatMap(a => a),
}
export const mutations = {
    setfilterOptions(state, {id_grafico, newData}) {
    state.filterOptions[ id_grafico] = newData
}
}
export const actions = {
    getFiltersOptions({commit}, newData) {
        commit('setfilterOptions', newData)
    }
}