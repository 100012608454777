import Swal from "sweetalert2"
import { ApiService } from "../../helpers/api.service"

export const state = {
    title: '',
    loading: false,
    show: false,
    usersID: [],
    permissionsArray: [],
    error: false,
    onSave: null,
}
export const getters = {
    title: state => state.title,
    show: state => state.show,
    loading: state => state.loading,
    usersID: state => state.usersID,
    permissionsArray: state => state.permissionsArray,
    error: state => state.error,
    onSave: state => state.onSave,
}
export const mutations = {
    
    setTitle(state, title) {
        state.title = title
    },
    setLoading(state, bool) {
        state.loading = bool
    },
    setShow(state, bool) {
        state.show = bool
    },
    setUsersID(state, usersID) {
        state.usersID = usersID
    },
    setPermissionsArray(state, permissionsArray) {
        state.permissionsArray = permissionsArray
    },
    setError(state, error) {
        state.error = error
    },
    setOnSave(state, onSave) {
        state.onSave = onSave
    }
}
export const actions = {
    async fetchPermissions({commit}, {rota, callback} = {}) {
        commit('setError', false)
        const body = {
            id_programa: null,
            condicao_adicional: "",
            lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
            data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
            navegador: false,
            ordenacao: null,
            quantidade_registros: 0,
            pagina: 0,
            rota: rota,
        }

        const res = await ApiService({
            method: 'POST',
            url: `/program`,
            data: body,
            headers: {'authorization': JSON.parse(localStorage.getItem('user')).token}
        })
        .then((res) => {
            if(!res.data.status){
                Swal.fire({
                    icon: "error",
                    text: res.data.mensagem ? res.data.mensagem : res.data.erro,
                    confirmButtonColor: "#34c38f",  
                    confirmButtonText: "OK",
                });
                commit('setShow', false);
                commit('setError', true)
                return false;
            }
            return res.data
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message,
                confirmButtonColor: "#34c38f",  
                confirmButtonText: "OK",
            });

            commit('setShow', false)
            commit('setError', true)
            return false
        })

        if(res){
            const permissions = callback(res.retorno)

            commit('setPermissionsArray', permissions)
            commit('setLoading', false)
        }
    },
};