export const state = {
    formType: 'navigation',
    title: ''
}
export const getters = {
    formType: state => state.formType,
}
export const mutations = {
    setFormType(state, formType) {
        state.formType = formType
        
        switch(formType){
            case 'navigation':
                document.title = `${state.title}`;
                break;
            case 'creation':
                document.title = `Criação | ${state.title}`;
                break;
            case 'edition':
                document.title = `Edição | ${state.title}`;
                break;
            case 'show':
                document.title = `Visualização | ${state.title}`;
                break;
        }
    },
    setTitle(state, title) {
        state.title = title
    }
}