import { DateTime } from "luxon"

export const state = {
    jobs: [],
    alwaysShowInScreen: true
    /*job object
    {
        id: null,
        title: null,
        message: null,
        status: 'running'
        date: new Date()
        show: true
    }*/
}
export const getters = {
    job: state => id => state.jobs.find(job => job.id === id),
    jobs: state => state.jobs,
    runningJobs: state => state.jobs.filter(job => job.status === 'running'),
    completedJobs: state => state.jobs.filter(job => job.status === 'done'),
    failedJobs: state => state.jobs.filter(job => job.status === 'failed'),
    showJobs: state => state.jobs.filter(job => job.show === true),
    alwaysShowInScreen: state => state.alwaysShowInScreen
}
export const mutations = {
    addJob(state, job) {
        const now = DateTime.now()
        job.date = now.toLocaleString(DateTime.DATETIME_SHORT)
        job.show = true
        state.jobs.unshift(job)
    },
    updateJob(state, data) {
        const index = state.jobs.findIndex(job => job.id === data.id)
        state.jobs[index] = data
        setTimeout(() => {
            this.commit('asyncJobs/hideJob', data.id)
        }, 6000)
    },
    setShowAllJobs(state, value) {
        state.jobs = state.jobs.map(job => {
            job.show = value
            return job
        })
    },
    showAllRunningJobs(state) {
        state.jobs = state.jobs.map(job => {
            job.show = job.status === 'running'
            return job
        })
    },
    setAlwaysShowInScreen(state, value) {
        state.alwaysShowInScreen = value
    },
    hideJob(state, jobId) {
        const job = state.jobs.find(job => job.id === jobId)
        if (job) {
            job.show = false
        }
    }
}