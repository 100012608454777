import { authHeader } from "./auth-header";
import { ApiService } from "../api.service";
import router from "../../router";

export const userService = {
  login,
  logout,
  register,
  getAll,
  confirmEmail,
  unlock,
  firstAcessPassword,
  validateVerificationCode,
  refresh
};

async function validateVerificationCode(params) {
  try {
    const { data } = await ApiService.post("/login", {
      email: params.email,
      senha: params.senha,
      otp: params.otp,
      sistema: params.sistema,
    });

    return data;
  } catch (error) {
    return handleResponse(error);
  }
}

async function login(data, redirectTo = false, onlyEmail = false) {
  const params = {
    email: data.email,
    sistema: data.sistema
  }
  if(!onlyEmail){
    params.senha = data.senha
    params.otp = data.otp
  }
  const res = await ApiService.post(onlyEmail ? "/external-register" : "/login", params)
    .then((response) => {
      const user = {
        token: response.data.token,
        refreshToken: response.data.refresh_token,
      };

      if (user.token) {
        localStorage.setItem(
          "sis_config",
          JSON.stringify(response.data.sis_config)
        );
        localStorage.setItem("user", JSON.stringify(user));

        if (response.data.rememberedUser) {
          localStorage.setItem(
            "rememberedUser",
            JSON.stringify(response.data.rememberedUser)
          );
        }

        if (redirectTo && redirectTo !== '/logout') {
          const params = router.resolve(redirectTo).params;
          const {registro_id, tipo} = router.resolve(redirectTo).query
          if (registro_id && tipo) {
            router.replace({ name: "dynamic-module-tree-program", params, query: {registro_id, tipo} });
          }else if(redirectTo !== '/'){
            router.replace({ replace: redirectTo });
          }else{
            router.replace({ name: "default" });
          }
        }

        if (response.data.redirect_url){
          router.replace({ path: response.data.redirect_url });
        } else {
          router.replace({ name: "default" });
        }
      }
      return user;
    })
    .catch((error) => {
      if(onlyEmail){
        return error.response.data.code
      }else{
        return handleResponse(error, true)
      }
    });

  return res;
}

async function firstAcessPassword(data) {
  const res = await ApiService.post("/first-password", {
    email: data.email,
    senha: data.senha,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => handleResponse(error, true));

  return res;
}

async function unlock(refreshToken, password, redirectTo = false) {
  const res = await ApiService({
    method: "POST",
    url: `/refresh-token`,
    data: {
      senha: password,
    },
    headers: { authorization: refreshToken },
  })
  .then((response) => {
    const user = { token: response.data.token, refreshToken };
    localStorage.setItem("user", JSON.stringify(user));
    if (redirectTo) {
      router.replace({ path: redirectTo });
    } else {
      router.replace({ name: "default" });
    }
    return user;
  })
  .catch((error) => handleResponse(error, true));

  return res;
}

async function refresh(token) {
  const res = await ApiService({
    method: "POST",
    url: '/refresh-menu',
    headers: { authorization: token },
  })

  return res.data.sis_config
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

async function register(user) {
  const res = await ApiService.post("/register", JSON.stringify(user)).catch(
    (error) => handleResponse(error, true)
  );
  return res;
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`/users`, requestOptions).then(handleResponse);
}

async function confirmEmail(form) {
  const res = await ApiService.post("/user/register/confirmationCode", form)
    .then((response) => {
      const user = { token: response.data.token };

      if (user.token) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      router.replace({ name: "default" });
    })
    .catch((error) => handleResponse(error));

  return res;
}

function handleResponse(error, isLogin = false) {
  if (error.response) {
    if (!isLogin && error.response.status == 401) {
      logout();
      location.reload(true);
    }

    const code = error.response.data.code;
    if (code == "NO_USER" || code == "INVALID_PASSWORD") {
      return "Usuário ou senha inválidos.";
    } else if (code == "BLOCKED_USER") {
      return "Usuário ou senha inválidos.<br>Contate o Administrador do Sistema.";
    } else if (
      code == "TFA_DISABLED" ||
      code == "OTP_NOT_INFORMED" ||
      code == "FIRST_ACCESS"
    ) {
      return code;
    }
    return error.response.data.mensagem;
  } else {
    return "Ocorreu um erro inesperado";
  }
}
